// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action, toJS } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRole,
} from '@utils';

const _default = {
  options: {},
  permissions: [],
  roles: [],
  role_and_permissions: null,
};

let app = {};

export class PermissionsStore {
  constructor(store) {
    makeObservable(this);

    AcAutoSave(this);

    app.store = store;
  }

  @observable
  permissions = [];

  @observable
  roles = [];

  @observable
  role_and_permissions = null;

  @computed
  get current_permissions() {
    if (!this.permissions?.length) return null;

    const collection = toJS(this.permissions);
    return collection.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
      // return a.name > b.name ? -1 : a.name < b.name ? 1 : 0;
    });
  }

  @computed
  get current_roles() {
    return toJS(this.roles);
  }

  @computed
  get current_role_and_permissions() {
    return toJS(this.role_and_permissions);
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: null,
  };

  @observable
  page = null;

  @observable
  per_page = null;

  @action
  resetParams = () => {
    this.page = null;
    this.per_page = null;
    this.order_by = { field: null, direction: null };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  get_all_permissions = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.permissions
      .get_all_permissions(params)
      .then((response) => {
        this.set(KEYS.PERMISSIONS, response?.data);

        // this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve permissions',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_all_roles = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.permissions
      .get_all_roles(params)
      .then((response) => {
        this.set(KEYS.ROLES, response?.data);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve roles',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_permissions_per_role = async (role, options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return await app.store.api.permissions
      .get_permissions_per_role(role, params)
      .then((response) => {
        // this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve roles',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  assign_permission_to_role = async (role, permission) => {
    this.setBusy(true);

    return await app.store.api.permissions
      .assign_permission_to_role({
        role_id: role.id,
        permission_id: permission.id,
      })
      .then(async (response) => {
        await this.get_all_roles();

        app.store.toasters.add({
          variant: 'success',
          title: 'Permission assigned',
          description: `Assigned permission <strong>${permission.name.toLowerCase()}</strong> to role <strong>${AcFormatRole(
            role.name
          )}</strong>`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to assign permission to role',
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  remove_permission_from_role = async (role, permission) => {
    this.setBusy(true);

    return await app.store.api.permissions
      .remove_permission_from_role({
        role_id: role.id,
        permission_id: permission.id,
      })
      .then(async (response) => {
        await this.get_all_roles();

        app.store.toasters.add({
          variant: 'success',
          title: 'Permission removed',
          description: `Removed permission <strong>${permission.name.toLowerCase()}</strong> from role <strong>${AcFormatRole(
            role.name
          )}</strong>`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to remove permission from role',
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default PermissionsStore;
